.container-form {
  background: var(--text-color-secondary);
  border-radius: 29px;
  border: 1px solid var(--secondary-color);
  padding: 30px 30px 0 30px !important;
  max-width: 90%;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}

.container-form h1 {
  color: var(--text-color) !important;
  font-family: var(--font-medium) !important;
  font-size: 3rem;
  padding: 15px 0;
  text-align: left;
  font-weight: 100 !important;
}

.pForm,
.pFormBody {
  color: var(--text-color) !important;
  font-family: var(--font-regular) !important;
  margin: 5px 0 5px 5px !important;
  font-size: 1.2em;
  text-align: left;
}

.pFormBody {
  color: var(--text-color) !important;
  text-align: center;
  font-family: var(--font-regular) !important;
}

.p-inputtext {
  border-radius: 7px !important;
  border: none !important;
  color: var(--text-color) !important;
  background: var(--tertiary-color) !important;
  font-size: 1.2em !important;
  font-family: var(--font-regular) !important;
  padding-left: 15px !important;
}

.p-inputtext:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.input-filled {
  color: var(--text-color) !important;
  background: var(--tertiary-color) !important;
  font-size: 1.2em !important;
}

.p-checkbox-box {
  border: solid 1px var(--secondary-color) !important;
  background: transparent !important;
  margin-bottom: 0 !important;
}

.field-checkbox label {
  color: var(--text-color) !important;
  font-family: var(--font-semibold) !important;
  font-size: .9rem !important;
  /* text-decoration: underline; */
  /* cursor: pointer; */
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: var(--secondary-color) !important;
}

.p-error {
  color: var(--secondary-color) !important;
  margin-left: 5px;
  font-family: var(--font-bold);
}

.p-dropdown {
  border: none !important;
  border-radius: 7px !important;
  color: var(--text-color) !important;
  background: var(--tertiary-color) !important;
  font-size: 1.2em !important;
}

.p-dropdown-label {
  border: none !important;
}

.listItemsFilter {
  font-size: 0.9rem;
  margin: 0 auto !important;
}

.titleModal2 {
  color: var(--secondary-color) !important;
}

.mensajeP,
.mensajeP:link {
  color: var(--text-color-secondary);
}

.mensajeP:link {
  text-decoration: underline;
}

.graciasM {
  text-align: center;
  padding: 30px 0 0 0 !important;
}

.graciasM img {
  width: 100%;
}

.reCAPTCHA {
  margin: 0 auto !important;
  text-align: center !important;
}

.p-dialog {
  box-shadow: none !important;
}

.p-dialog-content {
  border-radius: 49px !important;
  border: 1px solid var(--secondary-color) !important;
}

.p-dialog .p-dialog-content:last-of-type {
  border: 49px;
}

/*Medias Query*/
/*Mobile*/
@media only screen and (max-width: 600px) {

  .pForm,
  .pFormBody {
    font-size: 1.1rem;
  }

  .p-dropdown-label,
  .p-dropdown {
    font-size: 1.1rem !important;
  }

  .listItemsFilter {
    max-width: 350px;
  }

}

/*Tablet*/
@media only screen and (max-width: 768px) {}

/*Laptop*/
@media only screen and (max-width: 992px) {}

/*Large Screen*/
@media only screen and (max-width: 1200px) {}

/*Totem Screen*/
@media only screen and (min-height: 1800px) {

  .container-form {
    padding: 30px 60px 0 60px !important;
  }

  .container-form h1 {
    font-size: 4rem;
  }

  .pForm,
  .pFormBody {
    font-size: 1.8em;
  }

  .p-dropdown,
  .p-inputtext,
  .field-checkbox label {
    font-size: 1.5em !important;
  }


}