.banners {
  text-align: center;
}

.banners img {
  width: 100%;
}

/* Mobile */
@media (width <=600px) {}

/* Tablet */
@media (600px < width <=992px) {}

/* Laptop */
@media (992px < width <=1440px) {}

/* Totem */
@media (height > 1800px) {}