@font-face {
  font-family: "FSR";
  font-style: normal;
  src: local("FSJoey"),
    url("./assets/fonts/FSJoey.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "FSB";
  font-style: bold;
  src: local("FSJoey-Heavy"),
    url("./assets/fonts/FSJoey-Heavy.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "FSL";
  font-style: normal;
  src: local("FSJoey"),
    url("./assets/fonts/FSJoey-Light.ttf") format("truetype");
  font-display: swap;
}

:root {
  --color-bg: #F6F0E9 !important;
  --primary-color: #E40F2C !important;
  --secondary-color: #00A19B !important;
  --tertiary-color: #D9D9D9 !important;
  --text-color: #575756 !important;
  --text-color-secondary: #ffffff !important;
  --text-color-light: #869093;
  --font-regular: "FSR";
  --font-bold: "FSB";
  --font-medium: "FSR";
  --font-light: "FSL";
  /*--font-semibold: "QUSB";
  --font-italic: "SSI";
  --font-bolditalic: "SSBI";
  --font-thin: "ROT";*/
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background: var(--color-bg);
  background-image: url(./assets/images/bk-escritorio.png);
  background-repeat: no-repeat !important;
  background-position: top !important;
  background-size: 100% !important;
  background-attachment: fixed !important;
  font-family: var(--font-regular) !important;
  overflow-x: hidden !important;
}

*:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: var(--color-bg) !important;
  border: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 10px;
}

h1 {
  font-family: var(--font-bold);
  color: var(--primary-color);
  font-size: 3.8rem;
  line-height: .9;
  text-align: right;
}

h2 {
  font-family: var(--font-regular);
  color: var(--secondary-color);
  font-size: 1.5rem;
}

p {
  font-family: var(--font-regular);
  color: var(--text-color);
  font-size: 1.5rem;
}

.guias {
  border: solid 1px #000;
}

.visualMovil {
  display: none;
}

/*BTN BASICO*/
.btn-basic {
  background: var(--primary-color) !important;
  border: solid 2px var(--primary-color);
  color: var(--text-color-secondary);
  font-family: var(--font-bold);
  font-size: 1.5em;
  width: 100%;
  height: 40px;
  border-radius: 15px;
  transition: all 0.5s;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.btn-basic:hover,
.btn-basic:active,
.btn-basic:focus {
  background: var(--text-color-secondary) !important;
  color: var(--secondary-color);
  border: solid 2px var(--text-color-secondary);
}

.btn-basic:disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
  pointer-events: none !important;
}

/* Medias Query */
/* Mobile */
@media (width <=600px) {
  body {
    background-image: url(./assets/images/bk-movil.png);
    min-height: 100vw !important;
  }

  h1 {
    font-size: 1.8rem;
  }

  .visualMovil {
    display: flex
  }

  .visualEscritorio {
    display: none;
  }

  .btn-basic {
    font-size: 1.2rem;
  }
}

/* Tablet */
@media (600px < width <=992px) {}

/* Laptop */
@media (992px < width <=1440px) {}

/* Totem */
@media (height > 1800px) {}