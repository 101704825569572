.containerLoaderSimple {
  max-height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerLoader {
  height: 98vh;
  display: flex;
  align-items: center;
  justify-content: center;
}