.imgError {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgError img {
  width: 100%;
  max-width: 500px;
}