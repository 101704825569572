.logo {
  text-align: left;
}

.logo2 {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  padding: 0 5% !important;
}

.logo img {
  width: 95%;
  max-width: 200px;
}

/* Mobile */
@media (width <=600px) {}

/* Tablet */
@media (600px < width <=992px) {}

/* Laptop */
@media (992px < width <=1440px) {}

/* Totem */
@media (height > 1800px) {}